import React from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Page } from 'model/Page';
import { SurveyFormData } from 'shared/models/SurveyFormData';
import { useSurveyNavigation } from 'shared/hooks/useSurveyNavigation';
import { useSurveyFormSubmit } from 'shared/hooks/useSurveyFormSubmit';
import { SubmitErrorHandler, SubmitHandler } from 'react-hook-form/dist/types/form';
import { SaveAndContinueDialog } from 'component/SaveAndContinueDialog/SaveAndContinueDialog';
import { selectSurveyInitialAnswersFromSubmit } from 'selector/submitSelector';
import {
  findFirstErrorPageNumber,
  mapQuestionIdToFieldName,
} from 'shared/utils/form/surveyFormUtils';
import { Box } from '@mui/material';
import { useProgressBarValue, useRecaptchaTokenAtom } from 'shared/hooks/useAtoms';
import { removeSubmittedSurveyAsync } from 'action/surveyAction';
import { useAppDispatch } from 'store/appStore';
import { selectDistributionSlug } from 'selector/navigationSelector';
import { LogicDependencyMode } from 'model/LogicDependencyMode';
import { LogicDependency } from 'model/LogicDependency';
import { List } from 'immutable';
import { PageItemKind } from 'model/PageItemKind';
import { LogicType } from 'model/LogicType';

type SurveyFormProviderProps = {
  children: React.ReactNode;
  pages: ReadonlyArray<Page>;
};

export const useSurveyForm = () => useFormContext<SurveyFormData>();

export const SurveyFormProvider: React.VFC<SurveyFormProviderProps> = ({ children, pages }) => {
  const dispatch = useAppDispatch();
  const distributionSlug = useSelector(selectDistributionSlug);
  const surveyInitialAnswersFromSubmit = useSelector(selectSurveyInitialAnswersFromSubmit);
  const formResult = useForm<SurveyFormData>({
    mode: 'all',
    shouldFocusError: true,
    defaultValues: { question: surveyInitialAnswersFromSubmit },
  });

  const { goToPageNumber } = useSurveyNavigation();

  const { submitSurvey } = useSurveyFormSubmit();

  const [token] = useRecaptchaTokenAtom();
  const [, setProgressBarPercentage] = useProgressBarValue();

  const questions = pages
    .map((p) => p.getItems())
    .reduce((acc, items) => acc.concat(items), List())
    .filter((i) => i.getGeneralKind() === PageItemKind.General.Question);

  const questionsWithDependencyLogic = questions.filter(
    (pageItem) => pageItem.hasLogic() && pageItem.getLogic().getType() === LogicType.Dependency
  );

  const questionsWithoutDependencyLogic = questions.filter(
    (pageItem) =>
      pageItem.hasLogic() === false ||
      (pageItem.hasLogic() && pageItem.getLogic().getType() !== LogicType.Dependency)
  );

  const { getValues } = formResult;
  const updateProgressBar = (value: SurveyFormData) => {
    const totalVisibleQuestionsWithLogic = questionsWithDependencyLogic.reduce(
      (total, pageItem) => {
        const logicDependency = pageItem.getLogic() as LogicDependency;

        const logicQuestionFieldName = mapQuestionIdToFieldName(logicDependency.getQuestionId());
        const logicQuestionAnswers = logicDependency.getOptions().toArray();

        const logicAnswers = getValues(logicQuestionFieldName);
        const logicAnswersOptions = logicAnswers?.map((it) => it.option) ?? [];

        let isVisible: boolean;
        switch (logicDependency.getMode()) {
          case LogicDependencyMode.AllEqual:
            isVisible = logicQuestionAnswers.every((option) =>
              logicAnswersOptions.includes(option)
            );
            break;
          case LogicDependencyMode.SomeEqual:
            isVisible = logicQuestionAnswers.some((option) => logicAnswersOptions.includes(option));
        }
        return total + (isVisible ? 1 : 0);
      },
      0
    );
    const totalQuestions = questionsWithoutDependencyLogic.size + totalVisibleQuestionsWithLogic;

    const totalAnswers = Object.values(value.question).filter((v) => v?.length > 0).length;

    setProgressBarPercentage((totalAnswers * 100) / totalQuestions);
  };

  React.useEffect(() => {
    const subscription = formResult.watch(updateProgressBar);
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formResult.watch]);

  const onValidSubmit: SubmitHandler<SurveyFormData> = async (
    formData: SurveyFormData
  ): Promise<unknown> => {
    return submitSurvey({ formData, token }).then(() => {
      formResult.reset();
      dispatch(removeSubmittedSurveyAsync.resolved(distributionSlug));
    });
  };

  const onInvalidSubmit: SubmitErrorHandler<SurveyFormData> = (errors) => {
    const firstPageError = findFirstErrorPageNumber({ pages, errors });
    if (firstPageError) {
      goToPageNumber(firstPageError);
    }
  };

  return (
    <FormProvider {...formResult}>
      <Box
        component={'form'}
        onSubmit={formResult.handleSubmit(onValidSubmit, onInvalidSubmit)}
        sx={{ position: 'relative' }}
        noValidate
      >
        {children}
        <SaveAndContinueDialog />
      </Box>
    </FormProvider>
  );
};
