import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'store/appStore';
import { fetchSurvey } from 'action/surveyAction';
import { selectAccessCode, selectDistributionSlug } from 'selector/navigationSelector';

export const useFetchSurvey = () => {
  const dispatch = useAppDispatch();
  const slug = useSelector(selectDistributionSlug);
  const accessCode = useSelector(selectAccessCode);

  useEffect(() => {
    if (slug) {
      dispatch(fetchSurvey({ accessCode, slug }));
    }
  }, [slug, accessCode, dispatch]);
};
