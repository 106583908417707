import { SurveyRoute } from 'app/route';
import { AccessDeniedException } from 'exception/AccessDeniedException';
import { SurveyAlreadyCompletedException } from 'exception/SurveyAlreadyCompletedException';
import { SurveyClosedException } from 'exception/SurveyClosedException';
import { SurveyInDevelopmentException } from 'exception/SurveyInDevelopmentException';
import { List } from 'immutable';
import { AvailableSurvey } from 'model/AvailableSurvey';
import { Action } from 'redux-actions';
import {
  selectAccessCode,
  selectDistributionSlug,
  selectIsPreview,
  selectKioskCode,
  selectSubmitId,
} from 'selector/navigationSelector';
import { selectQueryString } from 'selector/routerSelector';
import { SurveyThunk } from 'store/SurveyThunk';
import { UnexpectedErrorException } from 'exception/UnexpectedErrorException';
import { UnauthorizedException } from 'exception/UnauthorizedException';
import { Answer } from 'model/Answer';

import { createAsyncActionCreator } from './asyncAction';
import { pushRoute } from './routerAction';

export const FETCH_SURVEY = 'Survey/FETCH_SURVEY';

export const fetchSurveyAsync = createAsyncActionCreator(FETCH_SURVEY);
type FetchSurvey = {
  accessCode?: string;
  slug?: string;
};

export const fetchSurvey = ({ accessCode, slug }: FetchSurvey = {}): SurveyThunk<
  Promise<unknown>
> => (dispatch, getState, container) => {
  dispatch(clearError());

  const isPreview = selectIsPreview(getState());
  const submitId = selectSubmitId(getState());
  const surveyPreviewQueryString = selectQueryString(getState());
  const distributionSlug = slug || selectDistributionSlug(getState());

  dispatch(fetchSurveyAsync.pending());

  const accessCodeValue = accessCode || selectAccessCode(getState());
  const kioskCodeValue = selectKioskCode(getState());

  const surveyPromise = isPreview
    ? container.surveyApi.preview(distributionSlug, surveyPreviewQueryString)
    : container.surveyApi.get(distributionSlug, accessCodeValue, submitId);

  return surveyPromise
    .then((result) => dispatch(fetchSurveyAsync.resolved(result)))
    .catch((error) => {
      if (
        error instanceof SurveyClosedException ||
        error instanceof SurveyInDevelopmentException ||
        error instanceof SurveyAlreadyCompletedException
      ) {
        dispatch(saveError(error.getApiCode(), error.message));
        // TODO Page should not be redirected after removing old design, check ApiErrorFallbackPage https://buzzing.atlassian.net/browse/WB3-1128
        dispatch(
          pushRoute(SurveyRoute.Survey.CLOSED_PATTERN, { distributionSlug }, { accessCode })
        );
      } else if (error instanceof AccessDeniedException) {
        dispatch(saveError(error.getApiCode(), error.message));

        // TODO Each routing should handle own error, remove redirection after removing old design https://buzzing.atlassian.net/browse/WB3-1128
        if (!accessCodeValue && !kioskCodeValue) {
          dispatch(
            pushRoute(SurveyRoute.Survey.KIOSK_PATTERN, {
              distributionSlug,
            })
          );
        }
      } else if (error instanceof UnauthorizedException) {
        dispatch(saveError(UnauthorizedException.API_CODE, error.message));
      } else {
        dispatch(saveError(UnexpectedErrorException.API_CODE, error.message));
      }
      dispatch(fetchSurveyAsync.rejected(error));
      throw error;
    });
};

type RedirectToSurvey = {
  accessCode?: string;
  slug?: string;
};
export const redirectToSurvey = ({
  accessCode,
  slug,
}: RedirectToSurvey = {}): SurveyThunk<void> => (dispatch, getState) => {
  if (!accessCode) {
    console.error('Redirect action without accessCode invoked');
    return;
  }

  dispatch(
    pushRoute(
      SurveyRoute.SURVEY_PATTERN,
      { distributionSlug: slug },
      { accessCode, submitId: selectSubmitId(getState()) }
    )
  );
};

export const CHECK_AVAILABLE_SURVEYS = 'Survey/CHECK_AVAILABLE_SURVEYS';
export type CheckAvailableSurveysAction = {
  type: typeof CHECK_AVAILABLE_SURVEYS;
  payload: AvailableSurvey.Instance[];
};
const checkAvailableSurveysAsync = createAsyncActionCreator<CheckAvailableSurveysAction['payload']>(
  CHECK_AVAILABLE_SURVEYS
);

export const checkAvailableSurveys = (
  accessCode?: string
): SurveyThunk<Promise<AvailableSurvey.Instance[]>> => (dispatch, getState, { surveyApi }) => {
  const distributionSlug = selectDistributionSlug(getState());
  const code = accessCode || selectAccessCode('accessCode');

  if (!code) {
    console.error('Cannot check available surveys without code');
    return;
  }

  dispatch(checkAvailableSurveysAsync.pending());
  return surveyApi
    .getAvailableSurveys(distributionSlug, accessCode)
    .then((availableSurveys) => {
      dispatch(checkAvailableSurveysAsync.resolved(availableSurveys));
      return availableSurveys;
    })
    .catch((errors) => {
      dispatch(checkAvailableSurveysAsync.rejected(errors));
      throw errors;
    });
};

export const SAVE_ERROR = 'Admin/SAVE_ERROR';
export type SaveError = Action<unknown>;

export const saveError = (code: string, message: string, data?: unknown): SaveError => ({
  type: SAVE_ERROR,
  payload: { code, message, data },
});

export const CLEAR_ERROR = 'Admin/CLEAR_ERROR';
export const clearError = () => ({
  type: CLEAR_ERROR,
});

export const FETCH_ACCESS_CODE_INFORMATION = 'Survey/FETCH_ACCESS_CODE_INFORMATION';
export const fetchAccessCodeInformationAsync = createAsyncActionCreator(
  FETCH_ACCESS_CODE_INFORMATION
);

export const fetchAccessCodeInformation = (): SurveyThunk<Promise<unknown>> => (
  dispatch,
  getState,
  container
) => {
  // it's used only in the email distrubtion where slug = id
  const distributionId = selectDistributionSlug(getState());
  const accessCode = selectAccessCode(getState());

  dispatch(fetchAccessCodeInformationAsync.pending());
  return container.surveyApi
    .fetchAccessCodeInformation(distributionId, accessCode)
    .then((result) => dispatch(fetchAccessCodeInformationAsync.resolved(result)))
    .catch((errors) => {
      dispatch(fetchAccessCodeInformationAsync.rejected(errors));
      throw errors;
    });
};

export const SAVE_SURVEY = 'Survey/SAVE_SURVEY';
export const saveSurveyAsync = createAsyncActionCreator(SAVE_SURVEY);

export const saveSurvey = ({
  answers,
  useBeacon = false,
  continueEmail,
  token,
}: {
  answers: List<Answer>;
  useBeacon?: boolean;
  continueEmail?: string;
  token?: string;
}): SurveyThunk<Promise<unknown>> => (dispatch, getState, container) => {
  const isPreview = selectIsPreview(getState());
  const submitId = selectSubmitId(getState());

  if (isPreview) {
    return Promise.resolve();
  }

  const distributionSlug = selectDistributionSlug(getState());
  const accessCode = selectAccessCode(getState());

  dispatch(saveSurveyAsync.pending());

  return container.surveyApi
    .save(distributionSlug, answers, accessCode, continueEmail, useBeacon, submitId, token)
    .then((result) => dispatch(saveSurveyAsync.resolved(result)))
    .catch((errors) => {
      dispatch(saveSurveyAsync.rejected(errors));
      throw errors;
    });
};

export const REMOVE_SUBMITTED_SURVEY = 'Survey/REMOVE_SUBMITTED_SURVEY';
export const removeSubmittedSurveyAsync = createAsyncActionCreator(REMOVE_SUBMITTED_SURVEY);

export const UNSUBSCRIBE = 'Survey/UNSUBSCRIBE';
export const unsubscribeAsync = createAsyncActionCreator(UNSUBSCRIBE);

export const unsubscribe = (): SurveyThunk<Promise<unknown>> => (dispatch, getState, container) => {
  dispatch(unsubscribeAsync.pending());

  const distributionSlug = selectDistributionSlug(getState());
  const accessCode = selectAccessCode(getState());

  return container.surveyApi
    .unsubscribe(distributionSlug, accessCode)
    .then((result) => dispatch(unsubscribeAsync.resolved(result)))
    .catch((errors) => {
      dispatch(unsubscribeAsync.rejected(errors));
      throw errors;
    });
};

export const FETCH_META = 'Survey/FETCH_META';

export const fetchMetaAsync = createAsyncActionCreator(FETCH_META);

export const fetchMeta = (): SurveyThunk<Promise<unknown>> => (dispatch, getState, container) => {
  const distributionSlug = selectDistributionSlug(getState());

  dispatch(fetchMetaAsync.pending());

  return container.surveyApi
    .getMeta(distributionSlug)
    .then((result) => dispatch(fetchMetaAsync.resolved(result)))
    .catch((error) => {
      dispatch(fetchMetaAsync.rejected(error));
      throw error;
    });
};
